<template>
  <div class="recharge-record-page">
    <div class="btn-area">
      <el-button size="small" @click="handleGoBack">返 回</el-button>
    </div>
    <div class="top-area">
      <div class="top-left">
        <div class="user-nick">{{ resultData.nickname ? resultData.nickname : '暂无昵称' }}</div>
        <div class="user-reg-time">{{ resultData.createTimeString }}</div>
      </div>
      <div class="top-right">
        <div class="top-right-item">
          <div class="label">充值总金额</div>
          <div class="value">{{ resultData.allRmbNum }}元</div>
        </div>
        <div class="top-right-item">
          <div class="label">金币总数量</div>
          <div class="value">{{ resultData.allCoinNum }}个</div>
        </div>
        <div class="top-right-item">
          <div class="label">已消费金币</div>
          <div class="value">{{ resultData.alreadyComsumeCoinNum }}个</div>
        </div>
        <div class="top-right-item">
          <div class="label">剩余金币</div>
          <div class="value">{{ resultData.surplusCoinNum }}个</div>
        </div>
      </div>
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:BACK_T_ORDER_MONEY_RECHARGE_RECEIVE_COUNT_PAGE_LIST')"
        height="100%"
        :data="resultData.list"
        v-loading="loading"
        row-key="id"
        style="width: 100%; min-width: 1200px"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column prop="orderNo" align="center" label="订单编号">
        </el-table-column>
        <el-table-column
          prop="createTimeString"
          align="center"
          label="交易时间"
        >
        </el-table-column>
        <el-table-column align="center" label="支付渠道">
          <template slot-scope="scope">
            <span v-show="scope.row.payType == 1">支付宝</span>
            <span v-show="scope.row.payType == 2">微信</span>
            <span v-show="scope.row.payType == 3">后台新增</span>
          </template>
        </el-table-column>
        <el-table-column prop="coinNum" align="center" label="金币数量">
        </el-table-column>
        <el-table-column
          prop="coinBalanceNum"
          align="center"
          label="剩余金币"
        ></el-table-column>
        <el-table-column align="center" label="支付金额">
          <template slot-scope="scope">
            <span>{{ scope.row.rmbNum }}元</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        v-show="authorities.includes('BACK:BACK_T_ORDER_MONEY_RECHARGE_RECEIVE_COUNT_PAGE_LIST')"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNum"
        :page-size="searchParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="resultData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getRechargeList } from "@/api/frontUser";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      searchParams: {
        pageNum: 1,
        pageSize: 10,
        userId: "",
      },
      loading: false,
      resultData: {
        total: 0,
        allCoinNum: 0, // 金币总数量
        allPeoPleNum: 0, // 总人数
        allRmbNum: 0, // 人民币总额
        alreadyComsumeCoinNum: 0, // 已消费金币
        createTimeString: "", // 注册时间
        nickname: "", // 用户昵称
        surplusCoinNum: 0, // 剩余金币
        list: [],
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  mounted() {
    this.searchParams.userId = this.$route.query.userId;
    this.getRechargeList();
  },
  methods: {
    /* 单页数量改变 */
    handleSizeChange(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getRechargeList();
    },
    /* 页码改变 */
    handleCurrentChange(pageNum) {
      this.searchParams.pageNum = pageNum;
      this.getRechargeList();
    },
    /* 获取充值详情 */
    async getRechargeList() {
      if(!this.authorities.includes('BACK:BACK_T_ORDER_MONEY_RECHARGE_RECEIVE_COUNT_PAGE_LIST')){
        return
      }
      try {
        this.loading = true
        const {
          allCoinNum,
          allPeoPleNum,
          allRmbNum,
          alreadyComsumeCoinNum,
          createTimeString,
          nickname,
          surplusCoinNum,
          pageList: { records, total, pages, current },
        } = await getRechargeList(this.searchParams);
        this.loading = false
        this.resultData = {
          allCoinNum,
          allPeoPleNum,
          allRmbNum,
          alreadyComsumeCoinNum,
          createTimeString,
          nickname,
          surplusCoinNum,
          total,
          list: records,
        };
        if(current > pages && pages) {
          this.searchParams.pageNum = pages 
          this.getRechargeList()
        }
      } catch (err) {
        this.loading = false
        let msg = err.msg ? err.msg : "获取充值列表发生异常！";
        console.error("getRechargeList: ", err);
        this.msgError(msg);
      }
    },
    /* 返回 */
    handleGoBack() {
      this.$router.back()
    },
  },
};
</script>
<style lang='scss' scoped>
.recharge-record-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0;
  .btn-area{
    height: 50px;
    line-height: 50px;
    text-align: right;
  }
  .top-area {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    .top-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .user-nick{
        font-weight: bold;
      }
      .user-reg-time{
        font-size: 12px;
        margin-top: 10px;
        color: #888888;
      }
    }
    .top-right {
      display: flex;
      align-items: center;
      .top-right-item {
        min-width: 120px;
        padding: 0 10px;
        height: 80px;
        margin-left: 20px;
        background-color: #f5f6f9;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label {
          color: #888888;
        }
        .value {
            font-size: 12px;
            margin-top: 10px;
        }
      }
    }
  }
  .list-area {
    margin-top: 20px;
    flex: 1;
    overflow-x: auto;
  }
  .bottom-area {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>