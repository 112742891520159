import request from "../utils/request";
import qs from 'qs'

/* 后台给客户端用户扣除金币 */
export const deductMoney = (params) => {
    return request.post(`/back/t/user/deductMoney?${qs.stringify(params, { indices: false })}`)
} 

/* 注册列表快速新增客户端用户 */
export const fastSaveClientUser = (params) => {
    return request.post(`/back/t/user/fastSaveClientUser?${qs.stringify(params, { indices: false })}`)
}

/* 注册列表快速修改客户端用户 */
export const fastUpdateClientUser = (params) => {
    return request.post(`/back/t/user/fastUpdateClientUser?${qs.stringify(params, { indices: false })}`)
}

/* 前台用户注册列表 */
export const getFrontUserList = (params) => {
    return request.post(`/back/t/user/front/reg/user/page/list?${qs.stringify(params, { indices: false })}`)
}

/* 前台用户会议次数列表 */
export const getMeetingRoomNumPage = (params) => {
    return request.post(`/back/t/user/meetingRoomNumPage?${qs.stringify(params, { indices: false })}`)
}

/* 充值收入统计 */
export const getRechargeList = (params) => {
    return request.get(`/back/t/user/recharge/receive/count/page/list?${qs.stringify(params, { indices: false })}`)
}

/* 后台给客户端用户充值金币 */
export const rechargeMoney = (params) => {
    return request.post(`/back/t/user/rechargeMoney?${qs.stringify(params, { indices: false })}`) 
}

/* 查询登录频率条件 */
export const getTimesList = () => {
    return request.get('/back/t/user/select/login/times/list')
}

/* 获取会议回放视频 */
export const getRecordUrl = (id) => {
    return request.post(`/back/t/user/getRecordingUrl/${id}`)
}

